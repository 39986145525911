import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { Role } from "../_helpers";
import { accountService } from "../_services";
import { H2Icon } from "./H2Icon";

function Nav() {
  const [user, setUser] = useState({});

  useEffect(() => {
    const subscription = accountService.user.subscribe((x) => setUser(x));
    return () => subscription.unsubscribe();
  }, []);

  // only show nav when logged in
  if (!user) return null;

  const isTest = process.env.REACT_APP_API_SERVER.includes("api-test");

  return (
    <nav className="navbar navbar-expand-sm navbar-dark bg-dark fixed-top">
      <NavLink exact to="/" className="navbar-brand">
        <H2Icon />
      </NavLink>
      <NavLink exact to="/" className="navbar-brand mb-0 h1">
        SiteMaster
      </NavLink>
      {isTest && (
        <NavLink exact to="/" className="navbar-brand mb-0 h1">
          Test
        </NavLink>
      )}
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarsExample03"
        aria-controls="navbarsExample03"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <NavLink to="/sites" className="nav-link">
              Sites
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/projects" className="nav-link">
              Projects
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/funding" className="nav-link">
              Funding
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/operations" className="nav-link">
              Operational KPIs
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/pl" className="nav-link">
              Site P&L
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/capex" className="nav-link">
              CAPEX
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/ubps" className="nav-link">
              UBP
            </NavLink>
          </li>
        </ul>

        <ul className="navbar-nav">
          <li className="nav-item">
            <NavLink to="/profile" className="nav-link">
              Profil
            </NavLink>
          </li>
          {user.role === Role.Admin && (
            <li className="nav-item">
              <NavLink to={`admin/users`} className="nav-link">
                Admin
              </NavLink>
            </li>
          )}
          <li className="nav-item active">
            <a onClick={accountService.logout} className="nav-link">
              Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export { Nav };
