import React from "react";
import SelectFilter from "@inovua/reactdatagrid-enterprise/SelectFilter";
import BoolFilter from "@inovua/reactdatagrid-enterprise/BoolFilter";
import BoolEditor from "@inovua/reactdatagrid-enterprise/BoolEditor";

import { projectService } from "../../_services/project.service";
import { selectService } from "../../_services";
import BaseList, { dateColumnProps } from "../../_components/BaseList";
import {
  renderEuro,
  renderFloat,
  renderInt,
} from "../../_components/Form/Utils";
import { defaultTo, get } from "lodash";
import { Role } from "../../_helpers";
import { CheckSquareIcon, icons, SquareIcon } from "../../_helpers/icons";

const renderIcon = ({ value }) => {
  const val = defaultTo(value, "").toUpperCase();
  return icons[val] ? <div title={value}>{icons[val]}</div> : value;
};

function List({ match }) {
  const getSelectValues = (selectField) => {
    return get(selectService, `value.${selectField}`, []);
  };

  const columns = [
    {
      name: "id",
      header: "Id",
      defaultFlex: 1,
      defaultVisible: false,
      visible: false,
      hideable: false,
    },
    {
      name: "idProject",
      header: "ID Project",
      defaultFlex: 1,
      minWidth: 80,
      notEmpty: true,
      unique: true,
      editable: false,
    },
    {
      name: "idSite",
      header: "ID Site",
      defaultFlex: 1,
      minWidth: 80,
      notEmpty: true,
      editable: false,
    },
    {
      name: "street",
      header: "Street",
      defaultFlex: 1,
      minWidth: 80,
      editable: false,
    },
    {
      name: "city",
      header: "City",
      defaultFlex: 1,
      minWidth: 80,
      editable: false,
    },
    {
      name: "projectType",
      header: "Type",
      defaultFlex: 1,
      minWidth: 80,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "Typ filtern...",
        dataSource: getSelectValues("projectTypes").map((c) => {
          return { id: c, label: c };
        }),
      },
    },
    {
      name: "projectStatus",
      header: "Status",
      defaultFlex: 1,
      minWidth: 80,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "Status filtern...",
        dataSource: getSelectValues("projectStatus").map((c) => {
          return { id: c, label: c };
        }),
      },
    },
    {
      name: "projectPriority",
      header: "Priority",
      defaultFlex: 1,
      minWidth: 80,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "Priority filtern...",
        dataSource: getSelectValues("projectPriorities").map((c) => {
          return { id: c, label: c };
        }),
      },
      render: renderIcon,
    },
    {
      name: "projectIdentified",
      header: "Identified",
      defaultFlex: 1,
      minWidth: 80,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "Identified filtern...",
        dataSource: getSelectValues("projectIdentifieds").map((c) => {
          return { id: c, label: c };
        }),
      },
      render: renderIcon,
    },
    {
      name: "projectLOI",
      header: "LOI",
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "LOI filtern...",
        dataSource: getSelectValues("projectLOIs").map((c) => {
          return { id: c, label: c };
        }),
        render: renderIcon,
      },
      render: renderIcon,
    },
    {
      name: "projectSite",
      header: "Site",
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "Site filtern...",
        dataSource: getSelectValues("projectSites").map((c) => {
          return { id: c, label: c };
        }),
      },
      render: renderIcon,
    },
    {
      name: "projectIPP",
      header: "IPP",
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "IPP filtern...",
        dataSource: getSelectValues("projectIPPs").map((c) => {
          return { id: c, label: c };
        }),
      },
      render: renderIcon,
    },
    {
      name: "projectIPPDate",
      header: "IPP Date",
      defaultFlex: 1,
      minWidth: 80,
      ...dateColumnProps,
    },
    {
      name: "projectSiteScoping",
      header: "Site-Scoping",
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "Site-Scopings filtern...",
        dataSource: getSelectValues("projectSiteScopings").map((c) => {
          return { id: c, label: c };
        }),
      },
      render: renderIcon,
    },
    {
      name: "projectSupply",
      header: "Supply",
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "Supply filtern...",
        dataSource: getSelectValues("projectSupplys").map((c) => {
          return { id: c, label: c };
        }),
      },
      render: renderIcon,
    },
    {
      name: "projectDemand",
      header: "Demand",
      defaultFlex: 1,
      minWidth: 80,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "Demand filtern...",
        dataSource: getSelectValues("projectDemands").map((c) => {
          return { id: c, label: c };
        }),
      },
      render: renderIcon,
    },
    {
      name: "fundingStatus",
      header: "Funding Status",
      defaultFlex: 1,
      minWidth: 80,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "Funding Status filtern...",
        dataSource: getSelectValues("projectFundingStatus").map((c) => {
          return { id: c, label: c };
        }),
      },
      render: renderIcon,
    },
    {
      name: "projectBC",
      header: "BC",
      defaultFlex: 1,
      minWidth: 80,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "BC filtern...",
        dataSource: getSelectValues("projectBCs").map((c) => {
          return { id: c, label: c };
        }),
      },
      render: renderIcon,
    },
    {
      name: "projectPID",
      header: "PID",
      defaultFlex: 1,
      minWidth: 80,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "PID filtern...",
        dataSource: getSelectValues("projectPIDs").map((c) => {
          return { id: c, label: c };
        }),
      },
      render: renderIcon,
    },
    {
      name: "projectPIDDate",
      header: "PID Date",
      defaultFlex: 1,
      minWidth: 80,
      ...dateColumnProps,
    },
    {
      name: "projectFID",
      header: "FID",
      defaultFlex: 1,
      minWidth: 80,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "FID filtern...",
        dataSource: getSelectValues("projectFIDs").map((c) => {
          return { id: c, label: c };
        }),
      },
      render: renderIcon,
    },
    {
      name: "projectFIDDate",
      header: "FID Date",
      defaultFlex: 1,
      minWidth: 80,
      ...dateColumnProps,
    },
    {
      name: "projectKickoffDate",
      header: "Kickoff Date",
      defaultFlex: 1,
      minWidth: 80,
      ...dateColumnProps,
    },
    {
      name: "projectPlanning",
      header: "Planning",
      defaultFlex: 1,
      minWidth: 80,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "Planning filtern...",
        dataSource: getSelectValues("projectPlannings").map((c) => {
          return { id: c, label: c };
        }),
      },
      render: renderIcon,
    },
    {
      name: "projectPermit",
      header: "Permit",
      defaultFlex: 1,
      minWidth: 80,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "Permit filtern...",
        dataSource: getSelectValues("projectPermits").map((c) => {
          return { id: c, label: c };
        }),
      },
      render: renderIcon,
    },
    {
      name: "projectConstruction",
      header: "Construction",
      defaultFlex: 1,
      minWidth: 80,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "Construction filtern...",
        dataSource: getSelectValues("projectConstructions").map((c) => {
          return { id: c, label: c };
        }),
      },
      render: renderIcon,
    },
    {
      name: "projectCommissioning",
      header: "Commissioning",
      defaultFlex: 1,
      minWidth: 80,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "Commissioning filtern...",
        dataSource: getSelectValues("projectCommissionings").map((c) => {
          return { id: c, label: c };
        }),
      },
      render: renderIcon,
    },
    {
      name: "projectGoliveDate",
      header: "Golive Date",
      defaultFlex: 1,
      minWidth: 80,
      ...dateColumnProps,
    },
    {
      name: "projectAgreedStartClientDate",
      header: "Agreed Start Client Date",
      defaultFlex: 1,
      minWidth: 80,
      ...dateColumnProps,
    },
    {
      name: "projectStartUBPDate",
      header: "Start UBP Date",
      defaultFlex: 1,
      minWidth: 80,
      ...dateColumnProps,
    },
    {
      name: "projectTechnology",
      header: "Technology",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "projetCAPEXFID",
      header: "CAPEX FID",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "projectAddCapacity",
      header: "Add Capacity",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderInt,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "count350bar",
      header: "350bar",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderInt,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "count700bar",
      header: "700bar",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderInt,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "projectTypeOfDemandSecurity",
      header: "Type Of Demand Security",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "projectCustomer",
      header: "Customer",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "projectSalesPriceClause",
      header: "Sales Price Clause",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "projectPIR",
      header: "PIR",
      defaultFlex: 1,
      minWidth: 80,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "PIR filtern...",
        dataSource: getSelectValues("projectPIRs").map((c) => {
          return { id: c, label: c };
        }),
      },
    },
    {
      name: "projectPIRDate",
      header: "PIR Date",
      defaultFlex: 1,
      minWidth: 80,
      ...dateColumnProps,
    },
    {
      name: "projectLeadPD",
      header: "Lead PD",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "projectLeadPM",
      header: "Lead PM",
      defaultFlex: 1,
      minWidth: 80,
    },
    { name: "sign", header: "Sign" },
    {
      name: "budget",
      header: "Budget",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "cashflow",
      header: "Cashflow",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "appropriationDeadline",
      header: "Appropriation Deadline",
      ...dateColumnProps,
    },
    {
      name: "projectClosure",
      header: "Closure",
      editor: BoolEditor,
      editStartEvent: "click",
      filterEditor: BoolFilter,
      render: ({ value }) => {
        let icon = <SquareIcon />;

        if (value) icon = <CheckSquareIcon />;
        return <div style={{ textAlign: "center" }}>{icon}</div>;
      },
    },
    {
      name: "demandTarget2024",
      header: "Demand Target 2024",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "demandTarget2025",
      header: "Demand Target 2025",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "demandTarget2026",
      header: "Demand Target 2026",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "demandTarget2027",
      header: "Demand Target 2027",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "demandTarget2028",
      header: "Demand Target 2028",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "demandTarget2029",
      header: "Demand Target 2029",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "demandTarget2030",
      header: "Demand Target 2030",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "info1",
      header: "Info 1",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "info2",
      header: "Info 2",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "info3",
      header: "Info 3",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "info3",
      header: "Info 3",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "commentConstruction",
      header: "Comment Construction",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "commentFunding",
      header: "Comment Funding",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "comment",
      header: "Comment",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "lastUpdateDate",
      header: "Last Update Date",
      defaultFlex: 1,
      minWidth: 80,
      ...dateColumnProps,
      dateFormat: "DD.MM.YYYY hh:mm",
      editable: false,
    },
    {
      name: "lastUpdateUser",
      header: "Last Update User",
      defaultFlex: 1,
      minWidth: 80,
      editable: false,
    },
  ];

  const filterValue = [
    { name: "idProject", operator: "contains", type: "string", value: "" },
    { name: "idSite", operator: "contains", type: "string", value: "" },
    { name: "street", operator: "contains", type: "string", value: "" },
    { name: "city", operator: "contains", type: "string", value: "" },
    { name: "projectType", type: "select", operator: "inlist", active: true },
    {
      name: "projectStatus",
      type: "select",
      operator: "inlist",
      active: true,
    },
    {
      name: "projectPriority",
      type: "select",
      operator: "inlist",
      active: true,
    },
    {
      name: "projectIdentified",
      type: "select",
      operator: "inlist",
      active: true,
    },
    { name: "projectLOI", type: "select", operator: "inlist", active: true },
    { name: "projectSite", type: "select", operator: "inlist", active: true },
    { name: "projectIPP", type: "select", operator: "inlist", active: true },
    {
      name: "projectIPPDate",
      operator: "after",
      type: "date",
      value: "",
    },
    {
      name: "projectSiteScoping",
      type: "select",
      operator: "inlist",
      active: true,
    },
    { name: "projectSupply", type: "select", operator: "inlist", active: true },
    { name: "projectDemand", type: "select", operator: "inlist", active: true },
    { name: "fundingStatus", type: "select", operator: "inlist", active: true },
    { name: "projectBC", type: "select", operator: "inlist", active: true },
    { name: "projectPID", type: "select", operator: "inlist", active: true },
    {
      name: "projectPIDDate",
      operator: "after",
      type: "date",
      value: "",
    },
    { name: "projectFID", type: "select", operator: "inlist", active: true },
    { name: "projectFIDDate", operator: "after", type: "date", value: "" },
    {
      name: "projectKickoffDate",
      operator: "after",
      type: "date",
      value: "",
    },
    {
      name: "projectPlanning",
      type: "select",
      operator: "inlist",
      active: true,
    },
    { name: "projectPermit", type: "select", operator: "inlist", active: true },
    {
      name: "projectConstruction",
      type: "select",
      operator: "inlist",
      active: true,
    },
    {
      name: "projectCommissioning",
      type: "select",
      operator: "inlist",
      active: true,
    },
    {
      name: "projectGoliveDate",
      operator: "after",
      type: "date",
      value: "",
    },
    {
      name: "projectAgreedStartClientDate",
      operator: "after",
      type: "date",
      value: "",
    },
    {
      name: "projectStartUBPDate",
      operator: "after",
      type: "date",
      value: "",
    },
    {
      name: "projectTechnology",
      operator: "contains",
      type: "string",
      value: "",
    },
    {
      name: "projetCAPEXFID",
      operator: "eq",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
    },
    {
      name: "projectAddCapacity",
      operator: "eq",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
    },
    {
      name: "count350bar",
      operator: "eq",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
    },
    {
      name: "count700bar",
      operator: "eq",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
    },
    {
      name: "projectTypeOfDemandSecurity",
      operator: "contains",
      type: "string",
      value: "",
    },
    {
      name: "projectCustomer",
      operator: "contains",
      type: "string",
      value: "",
    },
    {
      name: "projectSalesPriceClause",
      operator: "contains",
      type: "string",
      value: "",
    },
    {
      name: "projectPIR",
      type: "select",
      operator: "inlist",
      active: true,
    },
    {
      name: "projectPIRDate",
      operator: "after",
      type: "date",
      value: "",
    },
    {
      name: "projectLeadPD",
      operator: "contains",
      type: "string",
      value: "",
    },
    {
      name: "projectLeadPM",
      operator: "contains",
      type: "string",
      value: "",
    },
    {
      name: "appropriationDeadline",
      operator: "after",
      type: "date",
      value: "",
    },
    {
      name: "sign",
      operator: "contains",
      type: "string",
      value: "",
    },
    {
      name: "budget",
      operator: "eq",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
    },
    {
      name: "cashflow",
      operator: "eq",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
    },
    {
      name: "demandTarget2024",
      operator: "eq",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
    },
    {
      name: "demandTarget2025",
      operator: "eq",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
    },
    {
      name: "demandTarget2026",
      operator: "eq",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
    },
    {
      name: "demandTarget2027",
      operator: "eq",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
    },
    {
      name: "demandTarget2028",
      operator: "eq",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
    },
    {
      name: "demandTarget2029",
      operator: "eq",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
    },
    {
      name: "demandTarget2030",
      operator: "eq",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
    },
    {
      name: "info1",
      operator: "contains",
      type: "string",
      value: "",
    },
    {
      name: "info1",
      operator: "contains",
      type: "string",
      value: "",
    },
    {
      name: "info2",
      operator: "contains",
      type: "string",
      value: "",
    },
    {
      name: "info3",
      operator: "contains",
      type: "string",
      value: "",
    },
    {
      name: "commentConstruction",
      header: "Comment Construction",
      type: "string",
      value: "",
    },
    {
      name: "commentFunding",
      header: "Comment Funding",
      type: "string",
      value: "",
    },
    {
      name: "projectClosure",
      header: "Closure",
      type: "bool",
      operator: "eq",
    },
    {
      name: "comment",
      header: "Comment",
      type: "string",
      value: "",
    },
    {
      name: "lastUpdateDate",
      operator: "after",
      type: "date",
      value: "",
    },
    {
      name: "lastUpdateUser",
      operator: "contains",
      type: "string",
      value: "",
    },
  ];

  return (
    <BaseList
      columns={columns}
      match={match}
      filterValue={filterValue}
      service={projectService}
      storageKey="projects"
      title="Projects"
      enableAdd
      enableExport
      entityName="Project"
      idColumn="idProject"
      roles={[Role.Admin, Role.Controller, Role.PM_PD, Role.FM]}
    />
  );
}

export { List };
