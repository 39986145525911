import React from "react";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";

const BaseGrid = (props) => {
  const filterTypes = Object.assign(
    {},
    ReactDataGrid.defaultProps.filterTypes,
    {
      checkbox: {
        name: "checkbox",
        operators: [
          {
            name: "eq",
            fn: ({ value, filterValue, data }) => {
              if (filterValue == null) {
                return true;
              }
              return value === filterValue;
            },
          },
        ],
      },
    }
  );

  return (
    <ReactDataGrid
      filterTypes={filterTypes}
      idProperty="id"
      licenseKey="AppName=H2MOBILITYApp,Company=H2MOBILITY,ExpiryDate=2024-09-27T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=H2MOBILITYLicenseRef,Z=4416520842131328803-145950022-20816749331787427753-852614698"
      sortable
      {...props}
    />
  );
};

export default BaseGrid;
