import React from "react";

import { erpService } from "../../_services";
import "@inovua/reactdatagrid-enterprise/index.css";

import BaseList, { dateColumnProps } from "../../_components/BaseList";
import { Role } from "../../_helpers";
import {
  renderEuro,
  renderFloat,
  renderKilo,
} from "../../_components/Form/Utils";

function List({ match }) {
  const columns = [
    {
      name: "id",
      header: "Id",
      defaultFlex: 1,
      defaultVisible: false,
      visible: false,
      hideable: false,
    },
    {
      name: "idSite",
      header: "ID Site",
      defaultFlex: 1,
      minWidth: 80,
      editable: false,
    },
    {
      name: "street",
      header: "Street",
      defaultFlex: 1,
      minWidth: 80,
      editable: false,
    },
    {
      name: "city",
      header: "City",
      defaultFlex: 1,
      minWidth: 80,
      editable: false,
    },
    {
      name: "erpOPEXMonth",
      header: "OPEX Month",
      defaultFlex: 1,
      minWidth: 80,
      ...dateColumnProps,
    },
    {
      name: "erpGrossmarginKg",
      header: "Gross margin H2 sales incl. losses",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      headerAlign: "start",
      textAlign: "end",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpC2SKg",
      header: "Cost-to-serv €/Kg",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      headerAlign: "stahrt",
      textAlign: "end",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpEBITDAKg",
      header: "EBITDA €/Kg",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpEBITDA",
      header: "Site EBITDA",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpSoldKg",
      header: "Sold Kg",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderKilo,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpAvgSoldKg",
      header: "Avg. Sold H2 €/Kg",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpAvgPurchased",
      header: "Avg. Purch. H2 €/Kg",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpStocklossKg",
      header: "Stockloss Kg",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderKilo,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpStockloss",
      header: "Stockloss",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpRealGrossmargin",
      header: "real Gross Margin",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpMaintenance",
      header: "Maintenance",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpRepair",
      header: "Repair",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpEnergy",
      header: "Energy",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpEnergyKg",
      header: "Energy [kwH/Kg]",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderFloat,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpTransport",
      header: "Transport",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpRetailerCompensationLease",
      header: "Retailer Compensation Lease",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpInspection",
      header: "Inspection",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpSupervisor",
      header: "Supervisor",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpOtherOPEX",
      header: "Other OPEX",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpOperationalIncome",
      header: "Operational Income",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpOverhead",
      header: "Overhead",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpDepreciation",
      header: "Depreciation",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "erpSiteEBIT",
      header: "Site EBIT",
      defaultFlex: 1,
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    { name: "comment", header: "Comment", defaultFlex: 1, minWidth: 80 },
    {
      name: "lastUpdateDate",
      header: "Last Update Date",
      ...dateColumnProps,
      editable: false,
    },
    {
      name: "lastUpdateUser",
      header: "Last Update User",
      editable: false,
    },
  ];

  const filterValue = [
    { name: "idOperation", operator: "contains", type: "string", value: "" },
    { name: "idSite", operator: "contains", type: "string", value: "" },
    { name: "street", operator: "contains", type: "string", value: "" },
    { name: "city", operator: "contains", type: "string", value: "" },
    { name: "erpOPEXMonth", operator: "after", type: "date" },
    { name: "erpGrossmarginKg", type: "number" },
    { name: "erpC2SKg", type: "number" },
    { name: "erpEBITDAKg", type: "number" },
    { name: "erpEBITDA", type: "number" },
    { name: "erpSoldKg", type: "number" },
    { name: "erpAvgSoldKg", type: "number" },
    { name: "erpAvgPurchased", type: "number" },
    { name: "erpStocklossKg", type: "number" },
    { name: "erpStockloss", type: "number" },
    { name: "erpRealGrossmargin", type: "number" },
    { name: "erpMaintenance", type: "number" },
    { name: "erpRepair", type: "number" },
    { name: "erpEnergy", type: "number" },
    { name: "erpEnergyKg", type: "number" },
    { name: "erpTransport", type: "number" },
    { name: "erpRetailerCompensationLease", type: "number" },
    { name: "erpInspection", type: "number" },
    { name: "erpSupervisor", type: "number" },
    { name: "erpOtherOPEX", type: "number" },
    { name: "erpOperationalIncome", type: "number" },
    { name: "erpIT", type: "number" },
    { name: "erpOverhead", type: "number" },
    { name: "erpDepreciation", type: "number" },
    { name: "erpSiteEBIT", type: "number" },
    { name: "comment", operator: "contains", type: "string", value: "" },
    { name: "lastUpdateDate", operator: "after", type: "date", value: "" },
    { name: "lastUpdateUser", operator: "contains", type: "string", value: "" },
  ];

  return (
    <BaseList
      columns={columns}
      match={match}
      filterValue={filterValue}
      service={erpService}
      storageKey="erp"
      title="Site P&L"
      enableAdd
      enableExport
      enableImport
      entityName="P&L"
      idColumn="idOperation"
      roles={[Role.Admin, Role.Controller]}
    />
  );
}

export { List };
