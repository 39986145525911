import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { alertService, selectService } from "../../_services";
import { projectService } from "../../_services/project.service";
import { BaseField } from "../../_components/Form/BaseField";
import { DateField } from "../../_components/Form/DateField";
import { SelectField } from "../../_components/Form/SelectField";

function AddEdit({ history, match }) {
  const id = parseInt(match.params.id);
  const isAddMode = isNaN(id);

  const [initialValues, setInitialValues] = useState({
    idProject: "",
    idSite: "",
    projectType: "",
    projectStatus: "",
    projectPriority: "",
    projectIdentified: "",
    projectLOI: "",
    projectDemand: "",
    projectSite: "",
    projectIPP: "",
    projectIPPDate: null,
    projectSiteScoping: "",
    projectSupply: "",
    fundingStatus: "",
    projectBC: "",
    projectPID: "",
    projectPIDDate: null,
    projectFID: "",
    projectFIDDate: null,
    projectKickoffDate: null,
    projectPlanning: "",
    projectPermit: "",
    projectConstruction: "",
    projectCommissioning: "",
    projectGoliveDate: null,
    projectAgreedStartClientDate: null,
    projectStartUBPDate: null,
    projectTechnology: "",
    projetCAPEXFID: null,
    projectAddCapacity: null,
    count350bar: null,
    count700bar: null,
    projectTypeOfDemandSecurity: "",
    projectCustomer: "",
    projectSalesPriceClause: "",
    projectPIR: "",
    projectPIRDate: null,
    projectLeadPD: "",
    projectLeadPM: "",
    sign: "",
    budget: null,
    cashflow: null,
    appropriationDeadline: null,
    projectClosure: false,
    demandTarget2024: "",
    demandTarget2025: "",
    demandTarget2026: "",
    demandTarget2027: "",
    demandTarget2028: "",
    demandTarget2029: "",
    demandTarget2030: "",
    info1: "",
    info2: "",
    info3: "",
    comment: "",
    commentConstruction: "",
    commentFunding: "",
  });

  useEffect(() => {
    if (!isAddMode) {
      projectService.getById(id).then((project) =>
        setInitialValues((oldIntialValues) => ({
          ...project,
        }))
      );
    }
  }, [id, isAddMode]);

  const validationSchema = Yup.object().shape({
    idProject: Yup.string()
      .required("ID Project darf nicht leer sein.")
      .notOneOf(
        isAddMode ? selectService.value.projectIDs : [],
        "ID Project exisitiert bereits."
      ),
    idSite: Yup.string()
      .required("ID Site darf nicht leer sein.")
      .oneOf(selectService.value.siteIDs, "ID Site existiert nicht."),
    projectType: Yup.string().nullable(),
    projectPriority: Yup.string().nullable(),
    projectStatus: Yup.string().nullable(),
    projectIdentified: Yup.string().nullable(),
    projectLOI: Yup.string().nullable(),
    projectSite: Yup.string().nullable(),
    projectIPP: Yup.string().nullable(),
    projectIPPDate: Yup.date().nullable(),
    projectSiteScoping: Yup.string().nullable(),
    projectSupply: Yup.string().nullable(),
    projectDemand: Yup.string().nullable(),
    fundingStatus: Yup.string().nullable(),
    projectBC: Yup.string().nullable(),
    projectPID: Yup.string().nullable(),
    projectPIDDate: Yup.date().nullable(),
    projectFID: Yup.string().nullable(),
    projectFIDDate: Yup.date().nullable(),
    projectKickoffDate: Yup.date().nullable(),
    projectPlanning: Yup.string().nullable(),
    projectPermit: Yup.string().nullable(),
    projectConstruction: Yup.string().nullable(),
    projectCommissioning: Yup.string().nullable(),
    projectGoliveDate: Yup.date().nullable(),
    projectAgreedStartClientDate: Yup.date().nullable(),
    projectStartUBPDate: Yup.date().nullable(),
    projectTechnology: Yup.string().nullable(),
    projetCAPEXFID: Yup.number().nullable(),
    projectAddCapacity: Yup.number().integer().nullable(),
    count350bar: Yup.number().nullable(),
    count700bar: Yup.number().nullable(),
    projectTypeOfDemandSecurity: Yup.string().nullable(),
    projectCustomer: Yup.string().nullable(),
    projectSalesPriceClause: Yup.string().nullable(),
    projectPIR: Yup.string().nullable(),
    projectPIRDate: Yup.date().nullable(),
    projectLeadPD: Yup.string().nullable(),
    projectLeadPM: Yup.string().nullable(),
    sign: Yup.string()
      .nullable()
      .oneOf(selectService.value.signs, "Sign exisitiert nicht."),
    budget: Yup.number().nullable(),
    cashflow: Yup.number().nullable(),

    appropriationDeadline: Yup.date().nullable(),
    projectClosure: Yup.bool(),
    demandTarget2024: Yup.number().nullable(),
    demandTarget2025: Yup.number().nullable(),
    demandTarget2026: Yup.number().nullable(),
    demandTarget2027: Yup.number().nullable(),
    demandTarget2028: Yup.number().nullable(),
    demandTarget2029: Yup.number().nullable(),
    demandTarget2030: Yup.number().nullable(),
    info1: Yup.string().nullable(),
    info2: Yup.string().nullable(),
    info3: Yup.string().nullable(),
    comment: Yup.string().nullable(),
    commentConstruction: Yup.string().nullable(),
    commentFunding: Yup.string().nullable(),
  });

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    if (isAddMode) {
      createProject(fields, setSubmitting);
    } else {
      updateProject(id, fields, setSubmitting);
    }
  }

  function createProject(fields, setSubmitting) {
    projectService
      .create(fields)
      .then(() => {
        alertService.success("Project added successfully", {
          keepAfterRouteChange: true,
        });
        history.push(".");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  function updateProject(id, fields, setSubmitting) {
    projectService
      .update(id, fields)
      .then(() => {
        alertService.success("Update successful", {
          keepAfterRouteChange: true,
        });
        history.push("..");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ errors, touched, isSubmitting, isValid, setFieldValue, values }) => {
        const dateFieldProps = { errors, touched, setFieldValue, values };
        const textFieldProps = { errors, touched };
        const numberFieldProps = { errors, touched, type: "number" };

        return (
          <Form>
            <h1>{isAddMode ? "Project hinzufügen" : "Project bearbeiten"}</h1>
            <div className="form-row">
              <BaseField
                fieldName="idProject"
                fieldLabel="ID Project"
                {...textFieldProps}
                disabled={!isAddMode}
              />
              <SelectField
                fieldName="idSite"
                fieldLabel="Id Site"
                values={selectService.value.siteIDs}
                creatable={false}
                {...textFieldProps}
              />
              <SelectField
                fieldLabel="Type"
                fieldName="projectType"
                values={selectService.value.projectTypes}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "projectTypes")
                }
                {...textFieldProps}
              />
            </div>
            <div className="form-row">
              <SelectField
                fieldName="projectStatus"
                fieldLabel="Project Status"
                values={selectService.value.projectStatus}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "projectStatus")
                }
                {...textFieldProps}
              />
              <SelectField
                fieldName="projectPriority"
                fieldLabel="Project Priority"
                values={selectService.value.projectPriorities}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "projectPriority")
                }
                {...textFieldProps}
              />
              <SelectField
                fieldName="projectIdentified"
                fieldLabel="Identified"
                values={selectService.value.projectIdentifieds}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "projectIdentifieds")
                }
                {...textFieldProps}
              />
              <SelectField
                fieldName="projectLOI"
                fieldLabel="LOI"
                values={selectService.value.projectLOIs}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "projectLOIs")
                }
                {...textFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="projectSite"
                fieldLabel="Site"
                values={selectService.value.projectSites}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "projectSites")
                }
                {...textFieldProps}
              />
              <BaseField
                fieldName="projectIPP"
                fieldLabel="IPP"
                values={selectService.value.projectIPPs}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "projectIPPs")
                }
                {...textFieldProps}
              />
              <DateField
                fieldName="projectIPPDate"
                fieldLabel="IPP Date"
                {...dateFieldProps}
              />
              <BaseField
                fieldName="projectSiteScoping"
                fieldLabel="Site-Scoping"
                values={selectService.value.projectSiteScopings}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "projectSiteScopings")
                }
                {...textFieldProps}
              />
            </div>
            <div className="form-row">
              <SelectField
                fieldName="projectSupply"
                fieldLabel="Supply"
                values={selectService.value.projectSupplys}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "projectSupplys")
                }
                {...textFieldProps}
              />
              <SelectField
                fieldName="projectDemand"
                fieldLabel="Demand"
                values={selectService.value.projectDemands}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "projectDemands")
                }
                {...textFieldProps}
              />
              <SelectField
                fieldName="fundingStatus"
                fieldLabel="Funding Status"
                values={selectService.value.projectFundingStatus}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "projectFundingStatus")
                }
                {...textFieldProps}
              />
              <SelectField
                fieldName="projectBC"
                fieldLabel="BC"
                values={selectService.value.projectBCs}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "projectBCs")
                }
                {...textFieldProps}
              />
            </div>
            <div className="form-row">
              <SelectField
                fieldName="projectPID"
                fieldLabel="PID"
                values={selectService.value.projectPIDs}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "projectPIDs")
                }
                {...textFieldProps}
              />
              <DateField
                fieldName="projectPIDDate"
                fieldLabel="PID Date"
                {...dateFieldProps}
              />
            </div>
            <div className="form-row">
              <SelectField
                fieldName="projectFID"
                fieldLabel="Project FID"
                values={selectService.value.projectFIDs}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "projectFIDs")
                }
                {...textFieldProps}
              />
              <DateField
                fieldName="projectFIDDate"
                fieldLabel="FID Date"
                {...dateFieldProps}
              />
              <DateField
                fieldName="projectKickoffDate"
                fieldLabel="Project Kickoff Date"
                {...dateFieldProps}
              />
              <SelectField
                fieldName="projectPlanning"
                fieldLabel="Project Planning"
                values={selectService.value.projectPlannings}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "projectPlannings")
                }
                {...textFieldProps}
              />
            </div>
            <div className="form-row">
              <SelectField
                fieldName="projectPermit"
                fieldLabel="Permit"
                values={selectService.value.projectPermits}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "projectPermits")
                }
                {...textFieldProps}
              />
              <SelectField
                fieldName="projectConstruction"
                fieldLabel="Project Construction"
                values={selectService.value.projectConstructions}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "projectConstructions")
                }
                {...textFieldProps}
              />
              <SelectField
                fieldName="projectCommissioning"
                fieldLabel="Project Commissioning"
                values={selectService.value.projectCommissionings}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "projectCommissionings")
                }
                {...textFieldProps}
              />
            </div>
            <div className="form-row">
              <DateField
                fieldName="projectGoliveDate"
                fieldLabel="Project Golive Date"
                {...dateFieldProps}
              />
              <DateField
                fieldName="projectAgreedStartClientDate"
                fieldLabel="Project Agreed Start Client Date"
                {...dateFieldProps}
              />
              <DateField
                fieldName="projectStartUBPDate"
                fieldLabel="Project Start UBP Date"
                {...dateFieldProps}
              />
              <BaseField
                fieldName="projectTechnology"
                fieldLabel="Project Technology"
                {...textFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="projetCAPEXFID"
                fieldLabel="Project CAPEX FID"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="projectAddCapacity"
                fieldLabel="Project Add Capacity"
                {...numberFieldProps}
                step="1"
              />
              <BaseField
                fieldLabel="350bar"
                fieldName="count350bar"
                {...numberFieldProps}
              />
              <BaseField
                fieldLabel="700bar"
                fieldName="count700bar"
                {...numberFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="projectTypeOfDemandSecurity"
                fieldLabel="Type Of Demand Security"
                {...textFieldProps}
              />
              <BaseField
                fieldName="projectCustomer"
                fieldLabel="Customer"
                {...textFieldProps}
              />
              <BaseField
                fieldName="projectSalesPriceClause"
                fieldLabel="Sales Price Clause"
                {...textFieldProps}
              />
            </div>
            <div className="form-row">
              <SelectField
                fieldName="projectPIR"
                fieldLabel="PIR com"
                values={selectService.value.projectPIRs}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "projectPIRs")
                }
                {...textFieldProps}
              />
              <DateField
                fieldName="projectPIRDate"
                fieldLabel="PIR Date"
                {...dateFieldProps}
              />
              <BaseField
                fieldName="projectLeadPD"
                fieldLabel="Lead PD"
                {...textFieldProps}
              />
              <BaseField
                fieldName="projectLeadPM"
                fieldLabel="Lead PM"
                {...textFieldProps}
              />
            </div>
            <div className="form-row">
              <SelectField
                fieldName="sign"
                fieldLabel="Sign"
                values={selectService.value.signs}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "signs")
                }
                {...textFieldProps}
              />
              <BaseField
                fieldLabel="Budget"
                fieldName="budget"
                {...numberFieldProps}
              />
              <BaseField
                fieldLabel="Cashflow"
                fieldName="cashflow"
                {...numberFieldProps}
              />
              <DateField
                fieldName="appropriationDeadline"
                fieldLabel="Appropriation Deadline"
                {...dateFieldProps}
              />
              <BaseField
                fieldLabel="Closure"
                fieldName="projectClosure"
                type="checkbox"
                {...textFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="demandTarget2024"
                fieldLabel="Demand Target 2024"
                {...textFieldProps}
              />
              <BaseField
                fieldName="demandTarget2025"
                fieldLabel="Demand Target 2025"
                {...textFieldProps}
              />
              <BaseField
                fieldName="demandTarget2026"
                fieldLabel="Demand Target 2026"
                {...textFieldProps}
              />
              <BaseField
                fieldName="demandTarget2027"
                fieldLabel="Demand Target 2027"
                {...textFieldProps}
              />
              <BaseField
                fieldName="demandTarget2028"
                fieldLabel="Demand Target 2028"
                {...textFieldProps}
              />
              <BaseField
                fieldName="demandTarget2029"
                fieldLabel="Demand Target 2029"
                {...textFieldProps}
              />
              <BaseField
                fieldName="demandTarget2030"
                fieldLabel="Demand Target 2030"
                {...textFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="info1"
                fieldLabel="Info 1"
                {...textFieldProps}
              />
              <BaseField
                fieldName="info2"
                fieldLabel="Info 2"
                {...textFieldProps}
              />
              <BaseField
                fieldName="info3"
                fieldLabel="Info 3"
                {...textFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="comment"
                fieldLabel="Comment"
                {...textFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="commentConstruction"
                fieldLabel="Comment Construction"
                {...textFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="commentFunding"
                fieldLabel="Comment Funding"
                {...textFieldProps}
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-primary"
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Speichern
              </button>
              <Link to={isAddMode ? "." : ".."} className="btn btn-link">
                Abbrechen
              </Link>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export { AddEdit };
