import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  defaultTo,
  difference,
  differenceBy,
  filter,
  find,
  get,
  intersection,
  intersectionBy,
  isNull,
  map,
  some,
} from "lodash";
import moment from "moment/moment";
import DateFilter from "@inovua/reactdatagrid-enterprise/DateFilter";
import NumberFilter from "@inovua/reactdatagrid-enterprise/NumberFilter";

import { alertService } from "../_services/alert.service";
import { ExportCSV } from "./ExportCsv";
import { useMemo } from "react";
import { parseDecimalInput, renderFloat } from "./Form/Utils";
import { accountService } from "../_services";
import { Role } from "../_helpers";
import BaseGrid from "./BaseGrid";
import FilterDropdownButton from "./FilterDropdownButton";
import ColumnsDropdownButton from "./ColumnsDropdownButton";

const gridStyle = { minHeight: 500, height: "75vh" };
window.moment = moment;

export const dateColumnProps = {
  dateFormat: "DD.MM.YYYY",
  filterEditor: DateFilter,
  render: ({ value, cellProps: { dateFormat } }) => {
    const mDate = moment(value);
    if (mDate.isValid()) return mDate.format(dateFormat);
  },
  filterEditorProps: (props, { index }) => {
    // for range and notinrange operators, the index is 1 for the after field
    return {
      dateFormat: "DD.MM.YYYY",
      placeholder: index === 1 ? "Date is before..." : "Date is after...",
    };
  },
  renderEditor: (editorProps) => {
    const mDate = moment(editorProps.value);
    const dateValue = mDate.isValid() ? mDate.format("YYYY-MM-DD") : null;
    return (
      <input
        autoFocus
        value={dateValue}
        className="form-control"
        type="date"
        onChange={(event) => {
          const mDate = moment(event.target.value);
          const newValue = mDate.isValid() ? mDate : null;
          editorProps.onChange(newValue);
        }}
        onBlur={editorProps.onComplete}
        onKeyDown={(event) => {
          if (event.key === "Tab") {
            editorProps.onTabNavigation(true, event.shiftKey ? -1 : 1);
          } else if (event.key === "Enter") {
            editorProps.onComplete();
          }
        }}
      />
    );
  },
};

const percentFormat = new Intl.NumberFormat("en", {
  style: "percent",
  maximumFractionDigits: 2,
});
export const percentRender = (value) => percentFormat.format(value);

// const weightFormat = new Intl.NumberFormat("de-DE", {
//   style: "unit",
//   unit: "kilogram",
//   maximumFractionDigits: 2,
// });

// const weightRender = (value) => weightFormat.format(value);

const numberColumnProps = {
  render: renderFloat,
  getEditStartValue: (value) => (value) => renderFloat({ value }),
  filterEditor: NumberFilter,
};

const getButtonsColumn = ({ allowEdit, enableAdd, path, onDelete }) => ({
  name: "buttons",
  header: "",
  defaultFlex: 1,
  locked: "end",
  lockable: false,
  showColumnMenuTool: false,
  editable: false,
  resizable: false,
  sortable: false,
  minWidth: 100,
  render: ({ data }) => (
    <div className="btn-group" role="group">
      {enableAdd && allowEdit && (
        <Link
          to={`${path}/edit/${data.id}`}
          className="btn btn-sm btn-primary mb-2 me-1"
          title="Bearbeiten"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
          </svg>
        </Link>
      )}
      {allowEdit && (
        <button
          className="btn btn-sm btn-danger mb-2"
          onClick={() => {
            onDelete(data);
          }}
          title="Löschen"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </button>
      )}
    </div>
  ),
});

const getInitialConfig = (columns, storageKey, buttonColumn, allowEdit) => {
  if (columns.length > 0) {
    columns[1].locked = "start";
    columns[1].lockable = false;
    columns[1].hideable = false;
  }
  columns.forEach((columnItem) => {
    const column = columnItem;
    if (column.name === "id") column.visible = false;
    else column.visible = true;
    if (!column.minWidth) column.minWidth = 80;
    delete column.flex;
    if (!column.defaultFlex) column.defaultFlex = 1;
    if (column.type === "number") {
      const colProps = { ...numberColumnProps };
      if (column.render) colProps.render = column.render;
      if (column.getEditStartValue)
        colProps.getEditStartValue = column.getEditStartValue;
      Object.assign(column, colProps);
    }
    if (!allowEdit) {
      column.editable = false;
    }
  });

  const storedConfig = Object.assign(
    {
      sortInfo: {},
      columns: [],
      columnOrder: [],
      reservedViewportWidth: 0,
    },
    JSON.parse(get(localStorage, storageKey, "{}"))
  );
  //get stored Columns extended by new and reduced by old columns
  const storedColumns = intersectionBy(columns, storedConfig.columns, "name");
  storedColumns.forEach((c) => {
    const storedCol = find(storedConfig.columns, { name: c.name });
    if (storedCol) {
      if (storedCol.flex) c.flex = storedCol.flex;
      c.visible = get(storedCol, "visible", true);
    }
  });
  const newColumns = differenceBy(columns, storedColumns, "name");
  const initialColumns = storedColumns.concat(newColumns);
  if (allowEdit) initialColumns.push(buttonColumn);
  const initialColumnNames = map(initialColumns, (col) => col.name);

  const storedColumnOrder = intersection(
    storedConfig.columnOrder,
    initialColumnNames
  );
  // set new columns which are not present at end of columnOrder
  const initialColumnOrder = storedColumnOrder.concat(
    difference(initialColumnNames, storedColumnOrder)
  );
  return {
    sortInfo: storedConfig.sortInfo,
    reservedViewportWidth: storedConfig.reservedViewportWidth,
    columnOrder: initialColumnOrder,
    columns: initialColumns,
  };
};

const getColumnsValue = (gridConfig) => {
  return {
    columnOrder: gridConfig.columnOrder,
    sortInfo: gridConfig.sortInfo,
    columnVisible: map(gridConfig.columns, (col) => {
      const newCol = {
        name: col.name,
        visible: defaultTo(col.visible, true),
      };
      if (col.flex) {
        newCol.flex = col.flex;
      }
      return newCol;
    }),
  };
};

const getExportData = ({ data, columns, columnOrder }) => {
  return map(data, (row) => {
    const exportRow = {};
    columnOrder.forEach((columnName) => {
      if (columnName === "id") return;
      const column = find(columns, { name: columnName });
      if (!get(column, "visible", true)) return;
      exportRow[column.header] = row[columnName];
    });
    return exportRow;
  });
};

function BaseList({
  columns,
  editable,
  enableAdd,
  enableImport,
  enableExport,
  entityName,
  filterValue,
  idColumn,
  storageKey,
  match,
  service,
  title,
  roles,
  ...props
}) {
  const { path } = match;
  const user = accountService.userValue;

  const allowEdit = roles ? roles.indexOf(Role[user.role]) !== -1 : true;

  const buttonColumn = useMemo(() => {
    const onDelete = (rowData) => {
      // eslint-disable-next-line no-restricted-globals
      if (!confirm(`${entityName} "${rowData[idColumn]}" löschen?`)) return;
      service.delete(rowData.id).catch((error) => alertService.error(error));
    };
    return getButtonsColumn({ allowEdit, enableAdd, path, onDelete });
  }, [allowEdit, enableAdd, path, entityName, idColumn, service]);

  const initialGridConfig = useMemo(
    () => getInitialConfig(columns, storageKey, buttonColumn, allowEdit),
    [columns, storageKey, buttonColumn, allowEdit]
  );

  const [gridRef, setGridRef] = useState(null);
  const [data, setData] = useState(service.value);
  const [filteredData, setFilteredData] = useState(service.value);
  const [loading, setLoading] = useState(true);
  const [currentFilterValue, setCurrentFilterValue] = useState(filterValue);
  const [gridConfig, setGridConfig] = useState(initialGridConfig);

  const filterRef = useRef(null);
  const columnsRef = useRef(null);

  useEffect(() => {
    const subscricption = service.subject.subscribe({
      next: (newData) => {
        setData([...newData]);
        setFilteredData([...newData]);
      },
    });
    service
      .load()
      .then((newData) => {
        setData(newData);
        setLoading(false);
      })
      .catch((error) => {
        alertService.error(error);
        setLoading(false);
      });
    return () => subscricption.unsubscribe();
  }, [service]);

  // workaround to ensure all filter menus are visible
  useEffect(() => {
    if (!gridRef) return;
    if (gridRef.current) {
      gridRef.current.clearAllFilters();
    }
  }, [gridRef]);

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(gridConfig));
  }, [gridConfig, storageKey]);

  const onEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const column = find(columns, { name: columnId });
      const dataItem = find(data, { id: rowId });

      if (dataItem[columnId] === value) return;

      let newValue = value;

      if (column.parse) {
        newValue = column.parse(value);
      } else if (column.dateFormat) {
        if (!isNull(value)) {
          const mDate = moment(value, column.dateFormat);
          if (mDate.isValid()) {
            newValue = mDate.format();
          } else {
            alertService.warn(`${column.header} ist kein gültiges Datum.`);
            return;
          }
        }
      } else if (column.type === "number") {
        if (newValue === "") {
          newValue = null;
        } else if (newValue != null) {
          newValue = parseDecimalInput(value);
          if (isNaN(newValue) || !isFinite(newValue)) {
            alertService.warn(`${column.header} ist keine Zahl.`);
            return;
          }
        }
      }

      if (get(column, "notEmpty", false)) {
        if (newValue == null || newValue === "") {
          alertService.warn(`${column.header} darf nicht leer sein.`);
          return;
        }
      }

      if (get(column, "unique", false)) {
        const data = filter(service.value, (row) => row.id !== rowId);
        const values = map(data, (row) => row[columnId]);
        if (some(values, (val) => val === newValue)) {
          alertService.warn(`${column.header} ${value} existiert bereits.`);
          return;
        }
      }

      const updatedDataItem = { ...dataItem };
      updatedDataItem[columnId] = newValue;
      service.update(updatedDataItem.id, updatedDataItem).catch((error) => {
        alertService.error(error);
      });
    },
    [columns, data, service]
  );

  const onSortInfoChange = (sortInfo) => {
    setGridConfig(Object.assign({}, gridConfig, { sortInfo }));
  };

  const onColumnOrderChange = (columnOrder) => {
    setGridConfig(
      Object.assign({}, gridConfig, {
        columnOrder,
      })
    );
  };

  const onColumnVisibleChange = ({ column, visible }) => {
    column.visible = visible;
    const columns = [...gridConfig.columns];
    setGridConfig(Object.assign({}, gridConfig, { columns }));
  };

  const onBatchColumnResize = (batchColumnInfo, { reservedViewportWidth }) => {
    const colsMap = batchColumnInfo.reduce((acc, colInfo) => {
      const { column, flex } = colInfo;
      acc[column.name] = { flex };
      return acc;
    }, {});

    const columns = gridConfig.columns.map((c) => {
      return Object.assign({}, c, colsMap[c.name]);
    });

    setGridConfig(
      Object.assign({}, gridConfig, {
        columns,
        reservedViewportWidth,
      })
    );
  };

  const onColumnsReset = () => {
    localStorage.removeItem(storageKey);
    gridRef.current.setColumnSortInfo({
      dir: 1,
      name: "id",
    });
    setGridConfig(
      getInitialConfig(columns, storageKey, buttonColumn, allowEdit)
    );
    columnsRef.current.clearColumns();
  };

  const onFilterReset = () => {
    filterRef.current.clearFilter();
    gridRef.current.clearAllFilters();
  };

  const onSetColumns = (columnsVal) => {
    const columns = map(gridConfig.columns, (col) => {
      const colVisible = find(columnsVal.columnVisible, { name: col.name });
      if (colVisible) {
        col.visible = colVisible.visible;
        if (colVisible.flex) col.flex = colVisible.flex;
      }
      return col;
    });
    const newConfig = Object.assign({}, gridConfig, {
      columnOrder: columnsVal.columnOrder,
      sortInfo: columnsVal.sortInfo,
      columns,
    });
    gridRef.current.setColumnSortInfo(newConfig.sortInfo);
    setGridConfig(newConfig);
  };

  const onSetFilter = (filterVal) => {
    gridRef.current.clearAllFilters();
    filterVal.forEach((filter) => {
      if (filter.active) {
        gridRef.current.setColumnFilterValue(filter.name, filter.value);
      }
    });
    gridRef.current.setFilterValue(filterVal);
    setCurrentFilterValue(filterVal);
  };

  const onFilterValueChange = (filter) => {
    setFilteredData(get(gridRef, "current.data", []));
    setCurrentFilterValue(filter);
  };

  return (
    <div>
      <h1>{title}</h1>
      <div style={{ marginLeft: 0 }} className="row justify-content-start">
        {enableAdd && allowEdit && (
          <div className="col-xs-6 mr-1">
            <Link to={`${path}/add`} className="btn btn-sm btn-primary mb-2">
              Hinzuf&uuml;gen
            </Link>
          </div>
        )}
        {enableImport && allowEdit && (
          <div className="col-xs-6 mr-1">
            <Link to={`${path}/import`} className="btn btn-sm btn-primary mb-2">
              Import
            </Link>
          </div>
        )}
        {enableExport && (
          <div className="col-xs-6 mr-1">
            <ExportCSV
              csvData={getExportData({
                data: filteredData,
                columns: gridConfig.columns,
                columnOrder: gridConfig.columnOrder,
              })}
              fileName={`${title}_Export_${moment().format("DD_MM_YYYY")}`}
            />
          </div>
        )}
        <div className="col-xs-6 mr-1">
          <FilterDropdownButton
            ref={filterRef}
            listName={entityName}
            filterValue={currentFilterValue}
            onSetFilter={onSetFilter}
          />
        </div>
        <div className="col-xs-6 mr-1">
          <button
            className="btn btn-sm btn-secondary mb-2"
            onClick={onFilterReset}
          >
            Filter zurücksetzen
          </button>
        </div>
        <div className="col-xs-6 mr-1">
          <ColumnsDropdownButton
            ref={columnsRef}
            listName={entityName}
            columnsValue={getColumnsValue(gridConfig)}
            onSetColumns={onSetColumns}
          />
        </div>
        <div className="col-xs-6 mr-1">
          <button
            className="btn btn-sm btn-secondary mb-2"
            onClick={onColumnsReset}
          >
            Spalten zurücksetzen
          </button>
        </div>
      </div>
      <BaseGrid
        loading={loading}
        enableClipboard
        defaultCellSelection={[]}
        onReady={setGridRef}
        dataSource={data}
        defaultFilterValue={currentFilterValue}
        editable={editable}
        onEditComplete={onEditComplete}
        style={gridStyle}
        columns={gridConfig.columns}
        defaultSortInfo={gridConfig.sortInfo}
        columnOrder={gridConfig.columnOrder}
        reservedViewportWidth={gridConfig.reservedViewportWidth}
        onColumnVisibleChange={onColumnVisibleChange}
        onSortInfoChange={onSortInfoChange}
        onBatchColumnResize={onBatchColumnResize}
        onColumnOrderChange={onColumnOrderChange}
        onFilterValueChange={onFilterValueChange}
        {...props}
      />
    </div>
  );
}

BaseList.propTypes = {
  columns: PropTypes.array.isRequired,
  editable: PropTypes.bool,
  enableAdd: PropTypes.bool,
  enableExport: PropTypes.bool,
  enableImport: PropTypes.bool,
  entityName: PropTypes.string.isRequired,
  idColumn: PropTypes.string.isRequired,
  filterValue: PropTypes.array,
  match: PropTypes.object.isRequired,
  service: PropTypes.object.isRequired,
  storageKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  roles: PropTypes.array,
};

BaseList.defaultProps = {
  editable: true,
  enableAdd: false,
  enableExport: false,
  enableImport: false,
  filterValue: null,
  roles: null,
};

export default BaseList;
